import { isArray } from '@qcwp/utils'
import { useCommonStore } from '~~/src/store'
import { getDictType } from '~~/src/server/modules/base'
import type { DictResponse } from '~~/src/server/modules/base/types'

export interface OptionBase { label: string; value: string }
export function LogicDict() {
  const commonStore = useCommonStore()
  const { DICT_MAPS } = commonStore

  /**
   * 获取字典
   * setup中使用, 否则可能会nuxt instance undefined.  OR use callWithNuxt
   * @param dict 字典类型
   */
  async function getDict(dict: string) {
    if (!DICT_MAPS.has(dict) || !DICT_MAPS.get(dict)?.length) {
      const asyncData = await useAsyncData(`DICT_${dict}`, () => getDictType(dict))
      const { data } = useNuxtAsyncHandle(asyncData)
      setDict(dict, data || [])
    }

    return DICT_MAPS.get(dict)
  }

  function setDict(dict: string, data: any) {
    DICT_MAPS.set(dict, data)
  }
  function formatDict(data: DictResponse[], defaultValue: OptionBase = { label: '不限', value: '0' }): OptionBase[] {
    if (!data || !isArray(data) || !data.length)
      return []
    const newData: OptionBase[] = []

    if (defaultValue)
      newData.push(defaultValue)
    for (const item of data)
      newData.push({ label: item.dictLabel, value: item.dictValue })

    return newData
  }
  return {
    getDict,
    setDict,
    formatDict,
  }
}
